import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration/dist/interfaces';
import { MEDIA_BREAKPOINTS } from '../../../../../mediaQueries.const';
// import { MyAccountPopup } from './MyAccountPopup';
// import { LoginPopup } from './LoginPopup';
import { AccessibilityIcon } from '../AccessibilityIcon';
import { SearchIcon } from '../../../../partials/icon/icons';
import { Icon } from '../../../../partials/icon/Icon';
import { getStrokeIconStyle } from './header.util';
import { Editions } from './Editions';
import { useTheme } from '../../../../../theming/useTheme';
import { componentConfigThemeKey as threeRowsHeaderConfigThemeKey, ThreeRowsHeaderThemeProps } from './ThreeRowsHeader.theme';
import { NavigationLink } from '../oldHeader/primaryHeader/PrimaryHeader';
import { EditionsIconDataProps } from '../header/EditionsIcon';
// import { getStrokeIconStyle } from './header.util';

interface ActionsBarProps {
  showAccessibilityIcon?: boolean;
  iconColor: string;
  propertyEndpoint: string;
  editions: NavigationLink[] | null;
  editionEndpoint: string;
  editionsIcon: EditionsIconDataProps | null;
}

const getStyle = (iconColor: string) => {
  return StyleSheet.create({
    container: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      height: '100%',
      alignItems: 'center',
    },
    iconsWrapper: {
      display: 'flex',
      gap: '16px',
      padding: '0 16px 0 0',
    },
    popupWrapper: {
      flexDirection: 'column',
      backgroundColor: iconColor,
      padding: '24px',
      position: 'absolute',
      top: '78px',
      right: '0px',
      gap: '24px',
      display: 'flex',
      zIndex: 10,
      [MEDIA_BREAKPOINTS.medium]: {
        top: '88px',
      },
      boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.25)',
    },
    separator: {
      backgroundColor: iconColor,
      height: '32px',
      width: '1px',
      alignSelf: 'center',
    },
  });
};

export const ActionsBar: React.FunctionComponent<ActionsBarProps> = (
  {
    showAccessibilityIcon,
    iconColor,
    propertyEndpoint,
    editions,
    editionsIcon,
    editionEndpoint,
  },
) => {
  // const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  // const [isConnected, setIsConnected] = useState(false);
  // const onLoginButtonClick = () => setIsLoginModalOpen(!isLoginModalOpen);
  const { colors }: { colors: Colors } = useTheme();
  const style = getStyle(iconColor);
  const shouldEditions = editions && editions.length > 0;
  const themeProps = useTheme<ThreeRowsHeaderThemeProps>(threeRowsHeaderConfigThemeKey);

  const {
    secondLineSelectedIndicatorColor,
  } = themeProps;
  const onSearchClicked = () => {
    const url = new URL(`https://${propertyEndpoint}`);
    url.pathname += '/search';
    window.location.href = url.href;
  };

  // TODO: expose as setting in craft
  const SITES_WITH_SEARCH = ['swimsuit.si.com', 'lifestyle.si.com', 'www.path-2-career.com', 'www.the-wellness-way.com', 'www.home-is-love.com'];
  const includeSearchIcon = SITES_WITH_SEARCH.find(url => propertyEndpoint.includes(url)) || propertyEndpoint === 'www.si.com';

  return (
    <div className={css(style.container)}>
      <div className={css(style.iconsWrapper)}>
        {includeSearchIcon ? <Icon icon={SearchIcon} additionalStyle={getStrokeIconStyle(iconColor)} onClick={onSearchClicked} /> : null}
        {/* <Icon icon={LoginIcon} onClick={onLoginButtonClick} additionalStyle={getStrokeIconStyle(iconColor)} /> */}
        {showAccessibilityIcon && (
          <>
            {/* <div className={css(style.separator)} /> */}
            <AccessibilityIcon accessibilityIconNormalColor={iconColor} accessibilityIconHoverColor={iconColor} iconHeight={1.3} />
          </>
        )}
        {
            shouldEditions && (
            <Editions
              editions={editions!}
              editionEndpoint={editionEndpoint}
              colorTypeHover={colors.primary}
              colorTypeNormal={colors.black}
              colorTypeActive={secondLineSelectedIndicatorColor}
              editionsIconHoverColor={secondLineSelectedIndicatorColor}
              iconColor={iconColor}
              editionsMenuBackgroundColor={colors.white}
              editionsIcon={editionsIcon}
              dropdownIconColor={iconColor}
            />
            )
         }
      </div>
      {/* { isLoginModalOpen && !isConnected && (
        <div className={css(style.popupWrapper)}>
          <LoginPopup onLoginButtonClick={() => setIsConnected(true)} />
        </div>
      )}
      { isLoginModalOpen && isConnected && (
        <div className={css(style.popupWrapper)}>
          <MyAccountPopup onLogoutButtonClick={() => setIsConnected(false)} />
        </div>
      )} */}
    </div>
  );
};
