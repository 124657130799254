export const SITES_WITH_SEARCH = [
  '90min.com',
  'theplayerstribune.com',
  'verity-voice.com',
  'healthy-life-daily.com',
  'talk-the-talk.com',
  'daily-buzzz.com',
  'daily-zing.com',
  'yesterday-media.com',
  'to-bee-fit.com',
  'daily-chic.com',
  'daily-feest.com',
  'minimalist-mind.com',
  'for-your-heart.com',
  'oh-yes-daily.com',
  'go-do-u.com',
  'when-i-do.com',
];
